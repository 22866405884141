<template>
  <div>
    <b-alert variant="danger" v-model="showFailure" dismissible>
      Error updating section! Try again later.
    </b-alert>
    <b-form @submit="onSubmit">
      <b-form-group
        id="input-group-course"
        label="Course:"
        label-for="input-course"
      >
        <b-form-select
          id="input-course"
          v-model="form.courseId"
          :options="courseOptions"
        ></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-name" label="Name:" label-for="input-name">
        <b-form-input
          id="input-name"
          v-model="form.name"
          placeholder="Section name"
          :maxLength="1"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-time" label="Time:" label-for="input-time">
        <b-form-input
          id="input-time"
          v-model="form.time"
          placeholder="Enter section time"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-room" label="Room:" label-for="input-room">
        <b-form-input
          id="input-room"
          v-model="form.room"
          placeholder="Enter section room"
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="danger" :disabled="disableSubmit">
        Update
      </b-button>
    </b-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import sections from '../../api/sections';
import courses from '../../api/courses';

@Component({ props: { section: Object } })
export default class EditSectionForm extends Vue {
  courseOptions: { text: string; value: string }[] = [];
  form = {
    courseId: '',
    id: '',
    name: '',
    time: '',
    room: '',
  };

  showFailure = false;
  disableSubmit = false;

  mounted() {
    const section = this.$props.section;
    this.form = {
      courseId: section.courseId,
      id: section.id,
      name: section.name,
      time: section.time,
      room: section.room,
    };
    courses.getCourses().then((res) => {
      if (res.status) {
        res.data.forEach((course: Course) => {
          this.courseOptions.push({
            text: course.name,
            value: course.id,
          });
        });
      }
    });
  }

  clearForm() {
    this.form = {
      courseId: '',
      id: '',
      name: '',
      time: '',
      room: '',
    };
  }

  onSubmit(evt: Event) {
    evt.preventDefault();
    this.showFailure = false;
    this.disableSubmit = true;
    const sectionToSubmit = {
      courseId: this.form.courseId,
      id: this.form.id,
      name: this.form.name,
      time: this.form.time,
      room: this.form.room,
    };

    sections
      .putSection(this.$props.section.id, sectionToSubmit)
      .then((data) => {
        if (data.success) {
          this.clearForm();
          this.$emit('edit-success');
        } else {
          this.showFailure = true;
        }
        this.disableSubmit = false;
      });
  }
}
</script>
