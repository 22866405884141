var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h1',[_vm._v("EED Store")]),_c('Particles',{attrs:{"id":"particles","options":{
      particles: {
        number: {
          value: _vm.particleCount,
          density: {
            enable: true,
            value_area: 250,
          },
        },
        color: {
          value: '#bb0000',
        },
        shape: {
          type: 'circle',
          stroke: {
            width: 3,
            color: '#fff',
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: 'img/github.svg',
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 1,
          random: true,
          anim: {
            enable: true,
            speed: 1,
            opacity_min: 0.5,
            sync: false,
          },
        },
        size: {
          value: 16,
          random: true,
          anim: {
            enable: true,
            speed: 20,
            size_min: 1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 250,
          color: '#ffffff',
          opacity: 0.7,
          width: 2,
        },
        move: {
          enable: true,
          speed: 6,
          direction: 'none',
          random: true,
          straight: false,
          out_mode: 'out',
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: 'window',
        events: {
          onHover: {
            enable: !_vm.isMobile,
            mode: 'grab',
          },
          onClick: {
            enable: !_vm.isMobile,
            mode: 'repulse',
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 400,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 30,
            duration: 0.5,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 300,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }