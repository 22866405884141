<template>
  <div>
    <h4>Add New Laser Cutting Material</h4>
    <hr />
    <b-form @submit="onSubmit">
      <b-form-group
        id="input-group-name"
        label="Name:"
        label-size="lg"
        label-for="input-name"
      >
        <b-form-input
          id="input-name"
          v-model="form.name"
          required
          placeholder="Material Name for Student Selection"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-file-name"
        label="File Name:"
        label-size="lg"
        label-for="input-file-name"
      >
        <b-form-input
          id="input-file-name"
          v-model="form.fileName"
          required
          placeholder="Name to use for file referencing"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-course"
        label="Course:"
        label-size="lg"
        label-for="input-course"
      >
        <b-form-select
          id="input-course"
          v-model="form.courseId"
          :options="courseOptions"
          :valid="!!form.courseId"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="input-group-cost"
        label="Cost:"
        label-size="lg"
        label-for="input-cost"
      >
        <b-row>
          <b-col cols="6">
            <b-form-group
              id="input-group-cost-area"
              label="Area:"
              label-for="input-cost-area"
              label-cols-md="2"
            >
              <b-form-input
                id="input-cost-area"
                v-model="form.cost.area"
                required
                type="number"
                min="0"
                step="0.01"
                @change="roundAreaCost(form.cost.area)"
                @validate="checkCost"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              id="input-group-cost-length"
              label="Length:"
              label-for="input-cost-length"
              label-cols-md="2"
            >
              <b-form-input
                id="input-cost-length"
                v-model="form.cost.length"
                type="number"
                min="0"
                step="0.01"
                @change="roundLengthCost(form.cost.length)"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
        id="input-group-valid-until"
        label="Valid Until:"
        label-size="lg"
        label-for="input-valid-until"
      >
        <b-form-datepicker
          id="input-valid-until"
          v-model="form.validUntil"
          :min="minDate"
          locale="en"
          placeholder="Material Valid Until"
        ></b-form-datepicker>
      </b-form-group>

      <hr />
      <b-button type="submit" variant="danger"> Add Material </b-button>
    </b-form>
    <hr />
    <TableWrapper
      :all="materials"
      :shown="shownMaterials"
      v-on:pagingChange="pagingChange"
      v-if="hasMounted"
    >
      <b-table
        id="materials-table"
        striped
        hover
        :items="shownMaterials"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paging.current"
        :per-page="paging.per"
        responsive="sm"
      >
        <template #cell(cost)="data">
          <div>Area: {{ data.value.area }}</div>
          <div>Length: {{ data.value.length }}</div>
        </template>

        <template #cell(course)="data">
          <div>
            {{ data.value.name.toUpperCase() }}
          </div>
        </template>

        <template #cell(validUtil)="data">
          <div>{{ data.value }}</div>
        </template>

        <template #cell(actions)="data">
          <div class="text-right">
            <b-button
              variant="danger"
              @click="editedMaterial = data.item"
              v-b-modal.edit-lc-material-modal
            >
              <i class="fa fa-edit" />
            </b-button>
            &nbsp;
            <b-button
              variant="danger"
              @click="deletedMaterial = data.item"
              v-b-modal.delete-lc-material-modal
              ><i class="fa fa-trash"
            /></b-button>
          </div>
        </template>
      </b-table>
    </TableWrapper>
    <!-- Modals -->
    <b-modal id="edit-lc-material-modal" title="Update Material" hide-footer>
      <EditLCMaterialForm
        v-bind:material="editedMaterial"
        v-bind:courses="courses"
        v-on:edit-success="editSuccess"
      ></EditLCMaterialForm>
    </b-modal>
    <b-modal id="delete-lc-material-modal" title="Delete Material" hide-footer>
      <DeleteMaterialForm
        v-bind:material="deletedMaterial"
        v-on:delete-success="editSuccess"
      ></DeleteMaterialForm>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ProductType } from '@/utils/config';
import TableWrapper from '../utils/TableWrapper.vue';
import EditLCMaterialForm from './EditLCMaterialForm.vue';
import DeleteMaterialForm from '../DeleteMaterialForm.vue';
import { roundCost } from '@/utils/genericUtils';
import courses from '@/api/courses';
import materials from '@/api/materials';

@Component({
  components: { TableWrapper, EditLCMaterialForm, DeleteMaterialForm },
})
export default class PRMaterialIndex extends Vue {
  // Attributes for Table
  materials: Material[] = [];
  shownMaterials: Material[] = [];
  courses: Course[] = [];

  editedMaterial: Material | null = null;
  deletedMaterial: Material | null = null;

  paging = { per: 1, current: 1, total: 1 };
  pagingChange(incoming: Paging) {
    this.paging = incoming;
  }

  sortBy = 'materialName';
  sortDesc = true;
  fields = [
    { key: 'name', label: 'Material', sortable: true },
    { key: 'cost', label: 'Cost', sortable: false },
    { key: 'course', label: 'Course', sortable: true },
    { key: 'validUntil', label: 'Valid Until', sortable: true },
    { key: 'actions', label: '', sortable: false },
  ];

  // Attributes for Adding New Material
  minDate = new Date();

  courseOptions: { text: string; value: string | null }[] = [
    { text: 'Select Course', value: '' },
  ];

  form: RecursivePartial<Material> = {
    name: '',
    service: ProductType.LaserCut,
    cost: {
      area: 0,
      length: 0,
    },
    courseId: '',
    validUntil: undefined,
  };

  showAddSuccess = false;
  showDeleteSuccess = false;
  showEditSuccess = false;

  hasMounted = false;
  mounted() {
    this.loadCourses();
    this.loadMaterials();
    this.hasMounted = true;
  }

  loadCourses() {
    courses.getCourses().then((res) => {
      if (res.status) {
        this.courses = res.data;
        res.data.forEach((course: Course) => {
          this.courseOptions.push({
            text: course.name.toUpperCase(),
            value: course.id,
          });
        });
      }
    });
  }

  loadMaterials() {
    materials.getAllMaterials().then((res) => {
      this.materials = [];
      res.data.forEach((material: Material & { courses: Course[] }) => {
        if (material.service == ProductType.LaserCut) {
          const mat = material;
          mat.courseId = material.course.id;
          this.materials.push(mat);
        }
      });
      this.update(this.materials);
    });
  }

  update(shownMaterial: Material[]) {
    this.shownMaterials = shownMaterial;
  }

  deleteSuccess() {
    this.$bvModal.hide('delete-lc-material-modal');
    this.showDeleteSuccess = true;
    this.loadMaterials();
  }

  editSuccess() {
    this.$bvModal.hide('edit-lc-material-modal');
    this.showEditSuccess = true;
    this.loadMaterials();
  }

  clearForm() {
    this.form = {
      name: '',
      service: ProductType.LaserCut,
      cost: {
        area: 0,
        length: 0,
      },
      courseId: '',
      validUntil: undefined,
    };
  }

  roundAreaCost(value?: number) {
    this.form.cost!.area = roundCost(value!);
  }

  roundLengthCost(value?: number) {
    this.form.cost!.length = roundCost(value!);
  }

  checkCost() {
    return this.form.cost!.area != undefined && this.form.cost!.area > 0;
  }

  async onSubmit(evt: Event) {
    evt.preventDefault();

    materials.postMaterial(this.form).then((res) => {
      if (res.status) {
        this.showAddSuccess = true;
        this.loadMaterials();
      }
    });

    this.clearForm();
  }
}
</script>
