var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',[(['Team'].includes(_vm.base.location))?_c('div',[(_vm.base.team)?_c('a',{staticClass:"osu-link",attrs:{"href":'/teams/' + _vm.base.team.name}},[_vm._v(_vm._s(_vm.base.team.name)+" ")]):_c('div',[_vm._v("✖ Removed ✖")])]):_vm._e(),(['TA'].includes(_vm.base.location))?_c('div',[(_vm.base.user)?_c('div',[_vm._v(" "+_vm._s(_vm.base.user.fullName)+" ")]):_c('div',[_vm._v("✖ Removed ✖")])]):_vm._e(),(['Store'].includes(_vm.base.location))?_c('div',{class:{
        'truncate-wrap': _vm.truncate && _vm.wrap,
        'truncate-nowrap': _vm.truncate && !_vm.wrap,
      }},[_vm._v(" "+_vm._s(_vm.base.storeLocationDetails)+" ")]):_vm._e(),(['Other'].includes(_vm.base.location))?_c('div',{class:{
        'truncate-wrap': _vm.truncate && _vm.wrap,
        'truncate-nowrap': _vm.truncate && !_vm.wrap,
      }},[_vm._v(" "+_vm._s(_vm.base.otherLocationDetails)+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }