<template>
  <div>
    <h2>Courses</h2>
    <hr />

    <b-alert variant="success" v-model="showDeleteSuccess" dismissible>
      Course deleted!
    </b-alert>

    <b-alert variant="success" v-model="showEditSuccess" dismissible>
      Course updated!
    </b-alert>

    <b-btn v-b-modal.add-course-modal class="m-2" variant="danger"
      >Add Course</b-btn
    >

    <div class="mt-2">
      <b-table
        striped
        hover
        :items="formattedCourses"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        responsive="sm"
      >
        <template #cell(actions)="data">
          <div class="text-right">
            <b-button
              variant="danger"
              @click="editedCourse = getOriginalCourse(data.item)"
              v-b-modal.edit-course-modal
            >
              <i class="fa fa-edit" />
            </b-button>
            &nbsp;
            <b-button
              variant="danger"
              @click="deletedCourse = data.item"
              v-b-modal.delete-course-modal
              ><i class="fa fa-trash"
            /></b-button>
          </div>
        </template>
      </b-table>
    </div>

    <b-modal id="add-course-modal" title="Add Course" hide-footer>
      <AddCourseForm v-on:update="update"></AddCourseForm>
    </b-modal>

    <b-modal id="edit-course-modal" title="Update Course" hide-footer>
      <EditCourseForm
        v-on:edit-success="editSuccess"
        v-bind:course="editedCourse"
      ></EditCourseForm>
    </b-modal>

    <b-modal id="delete-course-modal" title="Delete Course" hide-footer>
      <DeleteCourseForm
        v-on:delete-success="deleteSuccess"
        v-bind:course="deletedCourse"
      ></DeleteCourseForm>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import AddCourseForm from '../../components/courses/AddCourseForm.vue';
import EditCourseForm from '../../components/courses/EditCourseForm.vue';
import DeleteCourseForm from '../../components/courses/DeleteCourseForm.vue';
import courses from '../../api/courses';
import { INF_BUDGET, INF_FILAMENT } from '../../../../api/utils/config';

@Component({
  components: { AddCourseForm, EditCourseForm, DeleteCourseForm },
})
export default class ViewCourses extends Vue {
  courses: Course[] | null = null;
  editedCourse: Course | null = null;
  deletedCourse: Course | null = null;

  showDeleteSuccess = false;
  showEditSuccess = false;

  sortBy = 'name';
  sortDesc = false;
  fields = [
    { key: 'name', sortable: true, label: 'Course Name' },
    { key: 'allocatedFilament', sortable: true, label: 'Allocated Filament' },
    { key: 'budget', sortable: true, label: 'Budget' },
    { key: 'actions', sortable: false, label: '' },
  ];

  // Computed property to format the courses
  get formattedCourses() {
    return (
      this.courses?.map((course) => ({
        ...course,
        allocatedFilament:
          course.allocatedFilament === INF_FILAMENT
            ? 'MAX FILAMENT'
            : course.allocatedFilament,
        budget: course.budget === INF_BUDGET ? 'MAX BUDGET' : course.budget,
      })) || []
    );
  }

  // Method to get the original course object
  getOriginalCourse(formattedCourse: any) {
    const originalCourse = { ...formattedCourse };

    if (formattedCourse.allocatedFilament === 'MAX FILAMENT') {
      originalCourse.allocatedFilament = INF_FILAMENT; // Set back to original value
    }

    if (formattedCourse.budget === 'MAX BUDGET') {
      originalCourse.budget = INF_BUDGET; // Set back to original value
    }

    return originalCourse;
  }

  deleteSuccess() {
    this.$bvModal.hide('delete-course-modal');
    this.showDeleteSuccess = true;
    this.update();
  }

  editSuccess() {
    this.$bvModal.hide('edit-course-modal');
    this.showEditSuccess = true;
    this.update();
  }

  update() {
    courses.getCourses().then((res) => {
      // auth check
      if (res.status === 403) {
        this.$router.push('/forbidden').catch(() => {});
      }
      this.courses = res.data;
    });
  }

  mounted() {
    this.update();
  }
}
</script>
