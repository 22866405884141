import { del, get, post, put } from '../shared/requests';

const getCourses = async () => {
  return await get('/courses');
};

const getCourseByName = async (name: string) => {
  return await get(`/courses/name/${name}`);
};

const getCourseById = async (id: string) => {
  return await get(`/courses/${id}`);
};

const postCourse = async (course: Course) => {
  return await post(`/courses/`, course);
};

const putCourse = async (courseId: string, course: Course) => {
  return await put(`/courses/${courseId}`, course);
};

const deleteCourse = async (courseId: string) => {
  return await del(`/courses/${courseId}`);
};

export default {
  getCourses,
  getCourseByName,
  getCourseById,
  postCourse,
  putCourse,
  deleteCourse,
};
