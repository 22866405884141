import { render, staticRenderFns } from "./ViewOrder.vue?vue&type=template&id=58e9f68a"
import script from "./ViewOrder.vue?vue&type=script&lang=ts"
export * from "./ViewOrder.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports