<template>
  <div>
    <b-alert variant="success" v-model="showSuccess" dismissible>
      Course added!
    </b-alert>
    <b-alert variant="danger" v-model="showFailure" dismissible>
      Error adding course! Try again later.
    </b-alert>
    <b-form @submit="onSubmit">
      <b-form-group
        id="input-group-courseName"
        label="Course Name:"
        label-for="input-courseName"
      >
        <b-form-input
          id="input-courseName"
          v-model="form.name"
          placeholder="Course Name"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-allocatedFilament"
        label="Allocated Filament:"
        label-for="input-allocatedFilament"
      >
        <b-form-input
          id="input-allocatedFilament"
          v-model.number="form.allocatedFilament"
          type="number"
          min="0"
          step="0.01"
          :disabled="disableFilament"
          :class="{ 'is-invalid': !validAllocatedFilament }"
          placeholder="Allocated Filament"
        ></b-form-input>
        <b-button variant="danger" @click="setMaxFilament" class="mt-2"
          >MAX FILAMENT</b-button
        >
        <b-form-invalid-feedback>
          Allocated Filament must be up to 10 digits, including 2 decimals.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-budget"
        label="Budget:"
        label-for="input-budget"
      >
        <b-form-input
          id="input-budget"
          v-model.number="form.budget"
          type="number"
          min="0"
          step="0.01"
          :disabled="disableBudget"
          :class="{ 'is-invalid': !validBudget }"
          placeholder="Budget"
        ></b-form-input>
        <b-button variant="danger" @click="setMaxBudget" class="mt-2"
          >MAX BUDGET</b-button
        >
        <b-form-invalid-feedback>
          Budget must be up to 10 digits, including 2 decimals.
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="text-right">
        <b-button
          type="submit"
          variant="danger"
          :disabled="disableSubmit || !formIsValid"
          class="mt-3"
        >
          Add Course
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import courses from '../../api/courses'; // Adjust the import path as needed
import { INF_BUDGET, INF_FILAMENT } from '../../../../api/utils/config';

@Component({})
export default class AddCourseForm extends Vue {
  form = {
    id: '',
    name: '',
    allocatedFilament: 0,
    budget: 0,
  };

  showSuccess = false;
  showFailure = false;
  disableSubmit = false;

  disableBudget = false;
  disableFilament = false;

  // Computed properties for validation
  get validAllocatedFilament() {
    if (this.disableFilament) return true;
    return this.validateDecimalField(this.form.allocatedFilament);
  }

  get validBudget() {
    if (this.disableBudget) return true;
    return this.validateDecimalField(this.form.budget);
  }

  // Overall form validation status
  get formIsValid() {
    return this.validAllocatedFilament && this.validBudget;
  }

  // Function to validate that the decimal field doesn't exceed the 10 digits limit
  validateDecimalField(value: number): boolean {
    const stringValue = value.toFixed(2); // Convert to string with 2 decimal places
    return stringValue.length <= 11; // Maximum 10 digits
  }

  // Sets the allocated filament to the maximum value
  setMaxFilament() {
    this.form.allocatedFilament = INF_FILAMENT;
    this.disableFilament = true;
  }

  // Sets the budget to the maximum value
  setMaxBudget() {
    this.form.budget = INF_BUDGET;
    this.disableBudget = true;
  }

  clearForm() {
    this.form = {
      id: '',
      name: '',
      allocatedFilament: 0,
      budget: 0,
    };
  }

  onSubmit(evt: Event) {
    evt.preventDefault();
    this.showFailure = false;
    this.showSuccess = false;
    this.disableSubmit = true;

    if (this.formIsValid) {
      courses.postCourse(this.form).then((data) => {
        if (data.success) {
          this.clearForm();
          this.showSuccess = true;
          this.$emit('update');
        } else {
          this.showFailure = true;
        }
        this.disableSubmit = false;
        this.disableFilament = false;
        this.disableBudget = false;
      });
    } else {
      this.showFailure = true;
      this.disableSubmit = false;
    }
  }
}
</script>
