type Product = {
  type: number;
  files: File[];
};

type OrderedPart = {
  name: string;
  partKey: string;
  quantityOrdered: number;
};

type CartState = {
  parts: OrderedPart[];
  products: Product[];
};

const cartState: StoreModule.State<CartState> = () => ({
  parts: [],
  products: [],
});

const mutations: StoreModule.Mutations<CartState> = {
  addProduct(state, value: Product) {
    state.products.push(value);
  },
  removeProduct(state, value: Product) {
    const index = state.products.indexOf(value);
    if (index != -1) {
      state.products.splice(index, 1);
    }
  },
  addPart(state, value: OrderedPart) {
    const index = state.parts.map((e) => e.name).indexOf(value.name);
    if (index != -1) {
      state.parts[index].quantityOrdered += 1;
    } else {
      state.parts.push(value);
    }
  },
  setPartQuantity(state, data: { name: string; quantityOrdered: number }) {
    const names = state.parts.map((e) => e.name);
    const index = names.indexOf(data.name);
    if (index != -1) {
      state.parts[index].quantityOrdered = data.quantityOrdered;
    }
  },
  removePart(state, value: OrderedPart) {
    const names = state.parts.map((e) => e.name);
    const index = names.indexOf(value.name);
    if (index != -1) {
      state.parts.splice(index, 1);
    }
  },
  clearCart(state) {
    state.parts = [];
    state.products = [];
  },
};

const actions: StoreModule.Actions = {
  addProduct({ commit }, product: Product) {
    commit('addProduct', product);
  },
  removeProduct({ commit }, product: Product) {
    commit('removeProduct', product);
  },
  addPart({ commit }, part: OrderedPart) {
    commit('addPart', part);
  },
  removePart({ commit }, part: OrderedPart) {
    commit('removePart', part);
  },
  setPartQuantity({ commit }, data: { name: string; quantityOrdered: number }) {
    commit('setPartQuantity', data);
  },
  clearCart({ commit }) {
    commit('clearCart');
  },
};

export default {
  state: cartState,
  actions,
  mutations,
};
