<template>
  <div>
    <h2>Services</h2>
    <hr />
    <!-- <b-alert show variant="danger">
      The service submission page is currently down for maintenance. Check back
      later to submit your service requests. If you have previously submitted
      service orders, these will still be completed during this down time.
    </b-alert> -->
    <b-card no-body>
      <b-tabs card>
        <b-tab title="3D Printing" active>
          <PRMaterialIndex />
        </b-tab>
        <b-tab title="Laser Cutting">
          <LCMaterialIndex />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import PRMaterialIndex from '../../components/3d_printing/PRMaterialIndex.vue';
import LCMaterialIndex from '../../components/laser_cutting/LCMaterialIndex.vue';

@Component({ components: { PRMaterialIndex, LCMaterialIndex } })
export default class ServiceIndex extends Vue {}
</script>
