import fun3 from './fun3';

let rendered = false;

//typescript won't let me do this without casting window
(window as any).peek = () => {
  if (!rendered) return;
  console.log(
    "Nice work! Have you seen the super secret page? I think it's named after a certain FEH-favorite poem...",
  );
  fun3.render();
};

const render = () => {
  rendered = true;
};

export default { render };
