import { del, get, post, put } from '../shared/requests';

async function getMaterials() {
  return await get('/materials');
}

async function getAllMaterials() {
  return await get('/materials/all');
}

async function postMaterial(material: RecursivePartial<Material>) {
  return await post(`/materials/create`, material);
}

async function editMaterial(id: string, material: RecursivePartial<Material>) {
  return await put(`/materials/edit/${id}`, material);
}

async function deleteMaterial(id: string) {
  return await del(`/materials/delete/${id}`);
}

export default {
  getMaterials,
  getAllMaterials,
  postMaterial,
  editMaterial,
  deleteMaterial,
};
