<template>
  <div>
    <b-alert variant="success" v-model="showSuccess" dismissible>
      Section added!
    </b-alert>
    <b-alert variant="danger" v-model="showFailure" dismissible>
      Error adding section! Try again later.
    </b-alert>
    <b-form @submit="onSubmit">
      <b-form-group
        id="input-group-course"
        label="Course:"
        label-for="input-course"
      >
        <b-form-select
          id="input-course"
          v-model="form.courseId"
          :options="courseOptions"
        ></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-name" label="Name:" label-for="input-name">
        <b-form-input
          id="input-name"
          v-model="form.name"
          placeholder="Section name"
          :maxLength="5"
          required
        ></b-form-input>
      </b-form-group>

      <!-- Change from select to input for Time -->
      <b-form-group id="input-group-time" label="Time:" label-for="input-time">
        <b-form-input
          id="input-time"
          v-model="form.time"
          placeholder="##:## AM/PM"
          required
        ></b-form-input>
      </b-form-group>

      <!-- Change from select to input for Room -->
      <b-form-group id="input-group-room" label="Room:" label-for="input-room">
        <b-form-input
          id="input-room"
          v-model="form.room"
          placeholder="Enter section room (e.g. HI 214)"
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="danger" :disabled="disableSubmit">
        Add Section
      </b-button>
    </b-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import sections from '../../api/sections';
import courses from '../../api/courses';
import roles from '../../shared/roles';

@Component({})
export default class AddSectionForm extends Vue {
  courseOptions: { text: string; value: string }[] = [];
  form = {
    courseId: '',
    id: '',
    name: '',
    time: '',
    room: '',
  };

  showSuccess = false;
  showFailure = false;
  disableSubmit = false;
  hasMounted = false;

  mounted() {
    if (roles.hasRole('student')) {
      this.$router.push('/forbidden');
      return;
    }
    courses.getCourses().then((res) => {
      if (res.status) {
        res.data.forEach((course: Course) => {
          this.courseOptions.push({
            text: course.name,
            value: course.name,
          });
        });
      }
    });

    this.clearForm();
    this.hasMounted = true;
  }

  clearForm() {
    this.form = {
      courseId: '',
      id: '',
      name: '',
      time: '',
      room: '',
    };
  }

  async onSubmit(evt: Event) {
    evt.preventDefault();
    this.showFailure = false;
    this.showSuccess = false;
    this.disableSubmit = true;
    const courseResponse = await courses.getCourseByName(this.form.courseId);
    const id = courseResponse.data.course.id;
    const sectionToPost = {
      courseId: id,
      id: this.form.id,
      name: this.form.name,
      time: this.form.time,
      room: this.form.room,
    };
    sections.postSection(sectionToPost).then((data) => {
      if (data.success) {
        this.clearForm();
        this.showSuccess = true;
        this.$emit('update');
      } else {
        this.showFailure = true;
      }
      this.disableSubmit = false;
    });
  }
}
</script>
