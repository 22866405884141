import fun from '../api/fun';

let rendered = false;

(window as any).feh = (code: number) => {
  if (!rendered) return;
  fun.postTeapot(code).then((res) => {
    if (res.success) {
      console.log(
        '%c 🎉 You win!! You should really apply to be a TA! We do fun projects like this all the time, and you would be a great fit! 🫖',
        'color: green; font-size: 24px',
      );
    }
  });
};

const render = () => {
  rendered = true;
};

export default { render };
