type AuthState = {
  admin: boolean;
  printing: boolean;
  laserCutting: boolean;
  proteusDeveloper: boolean;
  store: boolean;
  ta: boolean;
  userID: string;
};

const authState: StoreModule.State<AuthState> = () => ({
  admin: false,
  printing: false,
  laserCutting: false,
  proteusDeveloper: false,
  store: false,
  ta: false,
  userID: '',
});

const actions: StoreModule.Actions = {
  setPerms({ commit }, perms: Omit<AuthState, 'userID'>) {
    commit('setPerms', perms);
  },
  setName({ commit }, name: string) {
    commit('setName', name);
  },
};

const mutations: StoreModule.Mutations<AuthState> = {
  setPerms(state, value: Omit<AuthState, 'userID'>) {
    state.admin = value.admin;
    state.printing = value.printing;
    state.laserCutting = value.laserCutting;
    state.proteusDeveloper = value.proteusDeveloper;
    state.store = value.store;
    state.ta = value.ta;
  },
  setName(state, value: string) {
    state.userID = value;
  },
};

export default {
  state: authState,
  actions,
  mutations,
};
