<template>
  <div>
    <b-alert variant="danger" v-model="showFailure" dismissible>
      Error deleting material! Try again later.
    </b-alert>
    <b-form @submit="onSubmit">
      <b-alert variant="danger" show
        >Are you sure you want to delete this material?
        <br />
        <span class="font-weight-bolder">
          {{ material.name }} for {{ material.course.name.toUpperCase() }}
        </span></b-alert
      >
      <div></div>

      <b-button type="submit" variant="danger" :disabled="disableSubmit">
        Delete
      </b-button>
    </b-form>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import materials from '@/api/materials';

@Component({})
export default class DeleteMaterialForm extends Vue {
  @Prop(Object) material!: Material;

  showFailure = false;
  disableSubmit = false;

  onSubmit(evt: Event) {
    evt.preventDefault();
    this.showFailure = false;
    this.disableSubmit = true;

    if (!this.material?.id) {
      return;
    }

    materials.deleteMaterial(this.material.id).then((res) => {
      if (res.status) {
        this.$emit('delete-success');
      } else {
        this.showFailure = true;
      }
      this.disableSubmit = false;
    });
  }
}
</script>
