import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';
import cart from './modules/cart';

import { DEBUG } from '../utils/config';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    cart,
  },
  strict: DEBUG,
  plugins: [createPersistedState()],
});
