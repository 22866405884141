export const logFunMessagesToConsole = () => {
  console.log('%c ┏━━━┳━━━┳┓ ┏┓', 'color: red');
  console.log('%c ┃┏━━┫┏━━┫┃ ┃┃', 'color: red');
  console.log('%c ┃┗━━┫┗━━┫┗━┛┃', 'color: red');
  console.log('%c ┃┏━━┫┏━━┫┏━┓┃', 'color: red');
  console.log('%c ┃┃  ┃┗━━┫┃ ┃┃', 'color: red');
  console.log('%c ┗┛  ┗━━━┻┛ ┗┛', 'color: red');
  console.log('Nothing to see here...');
  console.log('But if you are curious, "peek" through the "window" 😉');
};
