<template>
  <div>
    <b-alert variant="danger" v-model="showFailure" dismissible>
      Error updating course! Try again later.
    </b-alert>
    <b-form @submit="onSubmit">
      <b-form-group
        id="input-group-course-name"
        label="Course Name:"
        label-for="input-course-name"
      >
        <b-form-input
          id="input-course-name"
          v-model="form.name"
          placeholder="Enter course name"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-budget"
        label="Budget:"
        label-for="input-budget"
      >
        <b-form-input
          id="input-budget"
          type="number"
          v-model.number="form.budget"
          :class="{ 'is-invalid': !validBudget }"
          placeholder="Enter budget"
        ></b-form-input>
        <b-button variant="danger" @click="setMaxBudget" class="mt-2"
          >MAX BUDGET</b-button
        >
        <b-form-invalid-feedback>
          Budget must be up to 10 digits, including 2 decimals.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-allocated-filament"
        label="Allocated Filament:"
        label-for="input-allocated-filament"
      >
        <b-form-input
          id="input-allocated-filament"
          type="number"
          v-model.number="form.allocatedFilament"
          :class="{ 'is-invalid': !validAllocatedFilament }"
          placeholder="Enter allocated filament"
        ></b-form-input>
        <b-button variant="danger" @click="setMaxFilament" class="mt-2"
          >MAX FILAMENT</b-button
        >
        <b-form-invalid-feedback>
          Allocated Filament must be up to 10 digits, including 2 decimals.
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="text-right">
        <b-button
          type="submit"
          variant="danger"
          :disabled="disableSubmit || !formIsValid"
        >
          Update Course
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import courses from '../../api/courses';
import { INF_BUDGET, INF_FILAMENT } from '../../../../api/utils/config';

@Component({ props: { course: Object } })
export default class EditCourseForm extends Vue {
  form = {
    id: '',
    name: '',
    budget: 0,
    allocatedFilament: 0,
  };

  showFailure = false;
  disableSubmit = false;

  async mounted() {
    const course = this.$props.course;
    this.form = {
      id: course.id,
      name: course.name,
      budget: course.budget,
      allocatedFilament: course.allocatedFilament,
    };
  }

  // Computed properties for validation
  get validAllocatedFilament() {
    return this.validateDecimalField(this.form.allocatedFilament);
  }

  get validBudget() {
    return this.validateDecimalField(this.form.budget);
  }

  // Overall form validation status
  get formIsValid() {
    return this.validAllocatedFilament && this.validBudget;
  }

  // Function to validate that the decimal field doesn't exceed the 10 digits limit
  validateDecimalField(value: number): boolean {
    const stringValue = value.toFixed(2); // Convert to string with 2 decimal places
    return stringValue.length <= 11; // Maximum 10 digits
  }

  // Sets the allocated filament to the maximum value
  setMaxFilament() {
    this.form.allocatedFilament = INF_FILAMENT;
  }

  // Sets the budget to the maximum value
  setMaxBudget() {
    this.form.budget = INF_BUDGET;
  }

  clearForm() {
    this.form = {
      id: '',
      name: '',
      budget: 0,
      allocatedFilament: 0,
    };
  }

  async onSubmit(evt: Event) {
    evt.preventDefault();
    this.showFailure = false;
    this.disableSubmit = true;

    if (this.formIsValid) {
      const courseToSubmit = {
        id: this.form.id,
        name: this.form.name,
        budget: this.form.budget,
        allocatedFilament: this.form.allocatedFilament,
      };

      courses.putCourse(this.form.id, courseToSubmit).then((data) => {
        if (data.success) {
          this.$emit('edit-success');
          this.clearForm();
        } else {
          this.showFailure = true;
        }
        this.disableSubmit = false;
      });
    } else {
      this.showFailure = true;
      this.disableSubmit = false;
    }
  }
}
</script>
