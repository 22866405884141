import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home/Home.vue';
import CategoriesIndex from '../views/categories/CategoriesIndex.vue';
import VendorsIndex from '../views/vendors/VendorsIndex.vue';
import PartsIndex from '../views/parts/PartsIndex.vue';
import PartsCreate from '../views/parts/PartsCreate.vue';
import PartsEdit from '../views/parts/PartsEdit.vue';
import LoansIndex from '../views/loans/LoansIndex.vue';
import ToolCreate from '../views/tools/ToolCreate.vue';
import ToolEdit from '../views/tools/ToolEdit.vue';
import ViewCart from '../views/orders/ViewCart.vue';
import ViewOrder from '../views/orders/ViewOrder.vue';
import OrdersIndex from '../views/orders/OrdersIndex.vue';
import ViewReturn from '../views/returns/ViewReturn.vue';
import AddService from '../views/services/AddService.vue';
import UsersIndex from '../views/users/UsersIndex.vue';
import ViewTeams from '../views/teams/ViewTeams.vue';
import TeamDetails from '../views/teams/TeamDetails.vue';
import ViewSections from '../views/sections/ViewSections.vue';
import ViewCourses from '../views/courses/ViewCourses.vue';
import ViewNotes from '../views/notes/ViewNotes.vue';
import ViewProtei from '../views/protei/ViewProtei.vue';
import ViewProteusDetails from '../views/protei/ViewProteusDetails.vue';
import ViewProteusIssue from '../views/protei/ViewProteusIssue.vue';
import AuthCallback from '../views/AuthCallback.vue';
import OverdueLoans from '../views/errors/OverdueLoans.vue';
import Forbidden from '../views/errors/Forbidden.vue';
import NotFound from '../views/errors/NotFound.vue';
import Teapot from '../views/errors/Teapot.vue';
import LowPartsIndex from '../views/parts/LowPartsIndex.vue';
import MaterialsIndex from '../views/services/MaterialsIndex.vue';

import { pointerPoem } from '../utils/config';

Vue.use(VueRouter);

const routes = [
  { path: '/', component: Home, meta: { title: 'Home - EED Store' } },
  {
    path: '/categories',
    component: CategoriesIndex,
    meta: { title: 'Categories - EED Store' },
  },
  {
    path: '/vendors',
    component: VendorsIndex,
    meta: { title: 'Vendors - EED Store' },
  },
  {
    path: '/parts',
    component: PartsIndex,
    meta: { title: 'Parts - EED Store' },
  },
  {
    path: '/parts/create',
    component: PartsCreate,
    meta: { title: 'Add Part - EED Store' },
  },
  {
    path: '/parts/lowparts',
    component: LowPartsIndex,
    meta: { title: 'Low Parts - EED Store' },
  },
  {
    path: '/parts/edit/:id',
    component: PartsEdit,
    meta: { title: 'Edit Part - EED Store' },
  },
  {
    path: '/loans',
    component: LoansIndex,
    meta: { title: 'Loans - EED Store' },
  },
  {
    path: '/loans/create',
    component: ToolCreate,
    meta: { title: 'Add Tool - EED Store' },
  },
  {
    path: '/loans/edit/:id',
    component: ToolEdit,
    meta: { title: 'Edit Tool - EED Store' },
  },
  {
    path: '/orders/cart',
    component: ViewCart,
    meta: { title: 'Shopping Cart - EED Store' },
  },
  {
    path: '/orders/all',
    component: OrdersIndex,
    meta: { title: 'Orders - EED Store' },
  },
  {
    path: '/orders/team',
    component: OrdersIndex,
    meta: { title: 'Orders - EED Store' },
  },
  {
    path: '/orders/:id',
    component: ViewOrder,
    meta: { title: 'Order Details - EED Store' },
  },
  {
    path: '/returns/:id',
    component: ViewReturn,
    meta: { title: 'Return Details - EED Store' },
  },
  {
    path: '/services/add',
    component: AddService,
    meta: { title: 'Services - EED Store' },
  },
  {
    path: '/materials',
    component: MaterialsIndex,
    meta: { title: 'Materials - EED Store' },
  },
  {
    path: '/users',
    component: UsersIndex,
    meta: { title: 'Users - EED Store' },
  },
  {
    path: '/teams',
    component: ViewTeams,
    meta: { title: 'Teams - EED Store' },
  },
  {
    path: '/teams/:name',
    component: TeamDetails,
    meta: { title: 'Team Details - EED Store' },
  },
  {
    path: '/sections',
    component: ViewSections,
    meta: { title: 'Sections - EED Store' },
  },
  {
    path: '/courses',
    component: ViewCourses,
    meta: { title: 'Courses - EED Store' },
  },
  {
    path: '/notes',
    component: ViewNotes,
    meta: { title: 'Notes - EED Store' },
  },
  {
    path: '/protei',
    component: ViewProtei,
    meta: { title: 'Protei - EED Store' },
  },
  {
    path: '/protei/:number/details',
    component: ViewProteusDetails,
    meta: { title: 'Proteus Details - EED Store' },
  },
  {
    path: '/protei/:number/issues/:id',
    component: ViewProteusIssue,
    meta: { title: 'Proteus Issues - EED Store' },
  },
  {
    path: '/overdue/',
    component: OverdueLoans,
    meta: { title: 'Overdue Loans - EED Store' },
  },
  { path: '/auth/callback', component: AuthCallback },
  {
    path: '/forbidden',
    component: Forbidden,
    meta: { title: 'Forbidden - EED Store' },
  },
  {
    path: `/${pointerPoem}`,
    component: Teapot,
    meta: { title: 'Teapot - EED Store' },
  },
  {
    path: '*',
    component: NotFound,
    meta: { title: 'Not Found - EED Store' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta?.title ? to.meta.title : 'EED Store';
  });
});

export default router;
