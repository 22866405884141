import { del, get, post, put } from '../shared/requests';

async function getCategories() {
  return await get('/categories');
}

async function postCategory(form: Category) {
  return await post(`/categories/create`, form);
}

async function editCategory(id: string, form: Category) {
  return await put(`/categories/edit/${id}`, form);
}

async function deleteCategory(id: string) {
  return await del(`/categories/delete/${id}`);
}

export default {
  getCategories,
  postCategory,
  editCategory,
  deleteCategory,
};
